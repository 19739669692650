<template>
    <b-card>
        <b-row class="mb-2">
            <b-col class="text-right">
                <b-button variant="primary" :to="{ name: 'PaymentNew' }">
                    <font-awesome-icon icon="plus"></font-awesome-icon> Nuevo
                </b-button>
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col sm="5" md="6" class="my-1">
                <b-input-group size="sm" prepend="Mostrar" append="registros">
                    <b-form-select v-model="payments.itemsPerPage" id="perPageSelect" size="sm" :options="payments.pageOptions"
                                   style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>

            <!--<b-col sm="7" md="6" class="my-1">
                <b-input-group prepend="Filtrar: ">
                    <b-input v-model="branches.filter" debounce="300"></b-input>
                </b-input-group>
            </b-col>-->
        </b-row>
        <b-table :items="loadData" :fields="payments.fields" :filter="payments.filter" :current-page="payments.currentPage"
                 :per-page="payments.itemsPerPage" empty-text="Sin registros" empty-filtered-text="Sin coincidencias"
                 striped bordered hover small show-empty :responsive="true" :sort-by="payments.sortBy" :sort-desc="payments.sortDesc"
                 ref="paymentsTable" class="text-left mb-0" @context-changed="tableContextChanged">
            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in payments.fields" :key="field.key"
                          :class="payments.filters[index] && payments.filters[index].value ? 'non-empty' : ''">
                        <b-row v-if="payments.filters[index]">
                            <b-col v-if="payments.filters[index].inputType === 'Select'">
                                <b-select v-model="payments.filters[index].value">
                                    <template v-if="'selectOptions' in payments.filters[index]">
                                        <b-select-option v-for="option in payments.filters[index].selectOptions" :value="option.value" :key="option.value">
                                            {{ option.text }}
                                        </b-select-option>
                                    </template>
                                    <template v-else>
                                        <b-select-option value="">Todos</b-select-option>
                                        <b-select-option value="TO_VERIFY">Por verificar</b-select-option>
                                        <b-select-option value="VERIFIED">Verificado</b-select-option>
                                        <b-select-option value="CANCELLED">Cancelado</b-select-option>
                                    </template>
                                </b-select>
                            </b-col>
                            <b-col v-else-if="payments.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="payments.filters[index].value" debounce="300"></b-input>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>
<!--            <template #custom-foot>-->
<!--                <b-tr>-->
<!--                    <b-th colspan="5"></b-th>-->
<!--                    <b-td class="text-right">-->
<!--                        <span class="font-weight-bold">Ingresos: </span>$ {{ formatCurrency(incomeTotal["amount"]) }}<br>-->
<!--                        <span class="font-weight-bold">Egresos: </span> $ {{ formatCurrency(expenseTotal["amount"]) }}<br>-->
<!--                        <span class="font-weight-bold">Total: </span>$ {{ formatCurrency(incomeTotal["amount"] - expenseTotal["amount"]) }}-->
<!--                    </b-td>-->
<!--                    <b-td class="text-right">-->
<!--                        <span class="font-weight-bold">Ingresos: </span>$ {{ formatCurrency(incomeTotal["paidAmount"]) }}<br>-->
<!--                        <span class="font-weight-bold">Egresos: </span> $ {{ formatCurrency(expenseTotal["paidAmount"]) }}<br>-->
<!--                        <span class="font-weight-bold">Total: </span>$ {{ formatCurrency(incomeTotal["paidAmount"] - expenseTotal["paidAmount"]) }}-->
<!--                    </b-td>-->
<!--                    <b-td class="text-right">-->
<!--                        <span class="font-weight-bold">Por cobrar: </span>$ {{ formatCurrency(incomeTotal["balance"]) }}<br>-->
<!--                        <span class="font-weight-bold">Por pagar: </span> $ {{ formatCurrency(expenseTotal["balance"]) }}<br>-->
<!--                    </b-td>-->
<!--                    <b-td colspan="2"></b-td>-->
<!--                </b-tr>-->
<!--            </template>-->

            <template #cell(folio)="row">
                <p><router-link :to="{ name: 'PaymentShow', params: { id: row.item.id }}" target="_blank">{{ row.item.folio }}</router-link></p>
            </template>

            <template #cell(type)="row">
                <p v-if="row.item.type === 'INCOME'">Ingreso</p>
                <p v-if="row.item.type === 'EXPENSE'">Egreso</p>
            </template>

            <template #cell(objectFolio)="row">
                <template v-if="row.item.objectId">
                    <router-link :to="{ name: 'BusinessOrderShow', params: { id: row.item.objectId }}"
                                 v-if="row.item.objectType === 'BusinessOrder'" target="_blank">{{ row.item.objectFolio }}</router-link>
                    <router-link :to="{ name: 'ServiceOrderShow', params: { id: row.item.objectId }}"
                                 v-if="row.item.objectType === 'ServiceOrder'" target="_blank">{{ row.item.objectFolio }}</router-link>
                    <router-link :to="{ name: 'ExpenseShow', params: { id: row.item.objectId }}"
                                 v-if="row.item.objectType === 'Expense'" target="_blank">{{ row.item.objectFolio }}</router-link>
                </template>
                <template v-else>{{ row.item.objectFolio }}</template>
            </template>

            <template #cell(amount)="row">
                <p>$ {{ formatCurrency(row.item.amount) }}</p>
            </template>

            <template #cell(paidAmount)="row">
                <p>$ {{ formatCurrency(row.item.paidAmount) }}</p>
            </template>

            <template #cell(balance)="row">
                <p>$ {{ formatCurrency(row.item.balance) }}</p>
            </template>

<!--            <template #cell(isApproved)="row">-->
<!--                <b-badge variant="warning" v-if="!row.item.isApproved" class="p-1">No</b-badge>-->
<!--                <b-badge variant="primary" v-if="row.item.isApproved" class="p-1">Si</b-badge>-->
<!--            </template>-->

            <template #cell(status)="row">
                <b-badge variant="danger" v-if="row.item.status === 'CANCELLED'" class="p-1">Cancelado</b-badge>
                <b-badge variant="info" v-if="row.item.status === 'TO_VERIFY'" class="p-1">Por verificar</b-badge>
                <b-badge variant="primary" v-if="row.item.status === 'VERIFIED'" class="p-1">Verificado</b-badge>
            </template>

            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" :to="{ name: 'PaymentShow', params: { id: row.item.id }}" v-b-tooltip.hover
                              title="Detalle" variant="outline-primary">
                        <font-awesome-icon icon="search"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Mostrando {{ start }} a {{ end }} de {{ payments.totalRows }} registro<template v-if="payments.totalRows !== 1">s</template>
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="payments.currentPage" :total-rows="payments.totalRows"
                                      :per-page="payments.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>
    </b-card>
</template>

<script>
import { paymentListUrl } from '@routes';
import * as constants from '@constants';
import tableStateMixin from '../../mixins/tableState';
import { formatCurrency } from '../../js/utilities';

export default {
    mixins: [tableStateMixin],
    data() {
        return {
            payments: {
                fields: [
                    // { key: 'id', label: 'id', sortable: true },
                    { key: 'paymentDate', label: 'Fecha', sortable: true },
                    { key: 'folio', label: 'Folio', sortable: true },
                    { key: 'type', label: 'Tipo', sortable: true },
                    { key: 'objectFolio', label: 'Concepto', sortable: true },
                    { key: 'reference', label: 'Referencia', sortable: true },
                    { key: 'businessEntityLegalName', label: 'Cliente/Proveedor', sortable: true },
                    { key: 'amount', label: 'Importe', sortable: true, thStyle: 'width: 8%', class: 'text-right' },
                    { key: 'paidAmount', label: 'Abonado', sortable: true, thStyle: 'width: 8%', class: 'text-right' },
                    { key: 'balance', label: 'Saldo', sortable: true, thStyle: 'width: 8%', class: 'text-right' },
                    // { key: 'isApproved', label: 'Aprobado', sortable: true, class: 'text-center' },
                    { key: 'status', label: 'Estatus', sortable: true, class: 'text-center' },
                    { key: 'actions', label: 'Acciones', class: 'text-center' }
                ],
                filters: [
                    { column: 'DATE_FORMAT(p.payment_date, \'%d/%m/%Y\')', type: 'STRING', value: '', format: '' },
                    { column: 'p.folio', type: 'STRING', value: '', format: '' },
                    { column: 'p.type', type: 'STRING', value: '', format: '', inputType: 'Select',
                        selectOptions: [{ value: '', text: 'Todos' }, { value: 'INCOME', text: 'Ingreso' }, { value: 'EXPENSE', text: 'Egreso' }]
                    },
                    { column: 'objectFolio', type: 'STRING', value: '', format: '' },
                    { column: 'p.reference', type: 'STRING', value: '', format: '' },
                    { column: 'p.business_entity_legal_name', type: 'STRING', value: '', format: '' },
                    { value: '' },
                    { value: '' },
                    { value: '' },
                    // { column: 'p.type', type: 'BOOLEAN', value: '', format: '', inputType: 'Select',
                    //     selectOptions: [{ value: '', text: 'Todos' }, { value: 'TRUE', text: 'Si' }, { value: 'FALSE', text: 'No' }]
                    // },
                    { column: 'p.status', type: 'STRING', value: '', format: '', inputType: 'Select' }
                ],
                filter: '',
                items: [],
                isLoading: false,
                pageOptions: [25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'p.payment_date',
                sortDesc: false
            },
            breadcrumb: {
                title: 'Pagos',
                path: []
            }
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.payments, this.tableState);
    },
    methods: {
        formatCurrency: formatCurrency,
        loadData(ctx) {
            if (ctx.sortBy !== '') {
                let data = {
                    filters: this.payments.filters.filter(filter => filter.value.trim() !== ''),
                    ...ctx
                };

                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(paymentListUrl(), data).then(response => {
                    if (response.data.code === constants.CODE_OK) {
                        this.payments.totalRows = response.data.totalRows;
                        this.payments.items = [];

                        for (let value of response.data.payments) {
                            this.payments.items.push(value);
                        }

                        return response.data.payments;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    console.error(error);

                    return [];
                });
            }
        },
        tableContextChanged(context) {
            this.payments.sortBy = context.sortBy;
            this.payments.sortDesc = context.sortDesc;

            this.saveTableState(this.$route.name, this.payments);
        }
    },
    watch: {
        'payments.filters': {
            handler() {
                this.$refs.paymentsTable.refresh();
                this.saveTableState(this.$route.name, this.payments);
            },
            deep: true
        }
    },
    computed: {
        start: function() {
            return this.payments.totalRows === 0 ? 0 : (this.payments.currentPage - 1) * this.payments.itemsPerPage + 1;
        },
        end: function() {
            let offset = (this.payments.currentPage - 1) * this.payments.itemsPerPage;

            return (this.payments.totalRows - offset) < this.payments.itemsPerPage ? this.payments.totalRows : offset + this.payments.itemsPerPage;
        },
        incomeTotal: function() {
            let item = {
                amount: 0,
                paidAmount: 0,
                balance: 0
            };

            for (let value of this.payments.items) {
                if (value.type === 'INCOME') {
                    item.amount += parseFloat(value.amount);
                    item.paidAmount += parseFloat(value.paidAmount);
                    item.balance += parseFloat(value.balance);
                }
            }

            return item;
        },
        expenseTotal: function() {
            let item = {
                amount: 0,
                paidAmount: 0,
                balance: 0
            };

            for (let value of this.payments.items) {
                if (value.type === 'EXPENSE') {
                    item.amount += parseFloat(value.amount);
                    item.paidAmount += parseFloat(value.paidAmount);
                    item.balance += parseFloat(value.balance);
                }
            }

            return item;
        }
    }
};
</script>

<style scoped>

</style>